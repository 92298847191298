import React from "react";

import "../News/Article.scss";
import Layout from "../../components/Layout/Layout";

const Article16092021 = () => {
  return (
    <Layout>
      <div className="article">
        <div className="article__ribbon">
          <div className="grid-container">
            <h1 className="title">
              Advanced IT technologies guarding the state border
            </h1>
            <p>
              General Inspectorate of Border Police of the Ministry of Internal
              Affairs of the Republic of Moldova increasing its technical
              component infrastructure, introducing modern technologies that
              allow organizing the protection of our state at a new level. With
              its many years of experience in the field of IT S&T Moldova
              supports Border Police to achieve these goals.{" "}
              <p>
                The General Inspectorate of the Border Police (IGPF) of the
                Republic of Moldova has implemented a telecommunications network
                in the state border area of the Republic of Moldova, within the
                project “Fixed and mobile telecommunications of the Border Guard
                Service”. The project was carried out under the coordination of
                the European Union Mission.
              </p>
              <p>
                The project was developed in two phases, phase I includes the
                border with Romania between Giurgiulesti and Ungheni, phase II
                provides radio coverage on the border with Romania and Ukraine,
                from Ungheni to Soroca and two base stations that cover
                Chisinau.
              </p>
              <p>
                The next and logical step was to expand the technologies to the
                uncovered sectors (Giurgiulesti to Olanesti). In this context
                S&T Moldova implemented the first phase of Radio relay
                communication project by helped to setting up the radio-relay
                transmissions system, serving as data transmission bus between
                different BPD subdivisions.{" "}
              </p>
              <p>
                This allowed providing each BPD subdivision with IT services,
                and facilitated integration of several services, data
                centralization and efficiency in transmitting different type of
                data: voice, video, digital. The system enables secure fixed and
                mobile communications, video surveillance and transmission of
                data between locations, thereby improving the quality of
                activity of BPD staff.
              </p>
              <p>
                Interconnection of the Integrated Information System of the
                Border Police with other national information systems ensures
                better boarding control, constant data exchange, allowing
                reducing the waiting time for crossing the border.
              </p>
              <p>
                In recent years, the authorities of the Republic of Moldova made
                the major transformation, especially in the development and
                spread of penetration of information elements, communications
                and modern elements of information technology, a transformation
                that requires upgrades and improvements for telecommunications
                networks. With a high degree of development experience and
                outstanding references in the mapping of complex business
                processes, S&T is also a partner in the field of system
                integration services, including consulting, development, and
                project management, providing strong implementation support and
                agile solutions.
              </p>
            </p>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Article16092021;
